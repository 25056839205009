// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_aboutContainer__2QfWJ {
  font-family: var(--main-font);
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ","sourcesContent":[".aboutContainer {\n    font-family: var(--main-font);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutContainer": `About_aboutContainer__2QfWJ`
};
export default ___CSS_LOADER_EXPORT___;
