const osramFrequenciesArray:Array<number> = [400, 424, 450, 473, 514, 555, 547, 595, 635, 685, 745];
const hamamatsuFrequenciesArray:Array<number> = [ 317.998,  320.704,  323.408,  326.11 ,  328.809,  331.505,
    334.198,  336.889,  339.577,  342.262,  344.944,  347.623,
    350.3  ,  352.973,  355.643,  358.31 ,  360.974,  363.635,
    366.293,  368.947,  371.598,  374.246,  376.89 ,  379.531,
    382.168,  384.802,  387.433,  390.06 ,  392.683,  395.302,
    397.918,  400.531,  403.139,  405.743,  408.344,  410.941,
    413.534,  416.123,  418.708,  421.289,  423.866,  426.439,
    429.008,  431.572,  434.133,  436.689,  439.241,  441.788,
    444.332,  446.87 ,  449.405,  451.935,  454.461,  456.982,
    459.498,  462.01 ,  464.518,  467.021,  469.519,  472.012,
    474.501,  476.985,  479.464,  481.938,  484.408,  486.873,
    489.332,  491.787,  494.237,  496.682,  499.122,  501.557,
    503.987,  506.412,  508.831,  511.246,  513.655,  516.059,
    518.458,  520.852,  523.24 ,  525.623,  528.001,  530.373,
    532.74 ,  535.102,  537.458,  539.809,  542.154,  544.494,
    546.828,  549.157,  551.48 ,  553.797,  556.109,  558.416,
    560.716,  563.011,  565.301,  567.584,  569.862,  572.135,
    574.401,  576.662,  578.916,  581.165,  583.409,  585.646,
    587.877,  590.103,  592.322,  594.536,  596.744,  598.946,
    601.141,  603.331,  605.515,  607.693,  609.865,  612.03 ,
    614.19 ,  616.343,  618.491,  620.632,  622.767,  624.896,
    627.019,  629.136,  631.247,  633.351,  635.449,  637.541,
    639.627,  641.707,  643.78 ,  645.847,  647.908,  649.963,
    652.011,  654.053,  656.089,  658.118,  660.141,  662.158,
    664.168,  666.172,  668.17 ,  670.162,  672.147,  674.126,
    676.098,  678.064,  680.024,  681.977,  683.924,  685.864,
    687.798,  689.726,  691.648,  693.563,  695.471,  697.373,
    699.269,  701.158,  703.041,  704.918,  706.788,  708.652,
    710.509,  712.36 ,  714.205,  716.043,  717.874,  719.7  ,
    721.519,  723.331,  725.137,  726.937,  728.731,  730.518,
    732.298,  734.073,  735.84 ,  737.602,  739.357,  741.106,
    742.848,  744.585,  746.314,  748.038,  749.755,  751.466,
    753.17 ,  754.869,  756.561,  758.246,  759.926,  761.599,
    763.266,  764.927,  766.581,  768.229,  769.871,  771.507,
    773.137,  774.76 ,  776.378,  777.989,  779.594,  781.193,
    782.786,  784.372,  785.953,  787.528,  789.096,  790.659,
    792.215,  793.766,  795.31 ,  796.849,  798.382,  799.908,
    801.429,  802.944,  804.453,  805.956,  807.454,  808.945,
    810.431,  811.911,  813.385,  814.854,  816.316,  817.774,
    819.225,  820.671,  822.111,  823.546,  824.975,  826.398,
    827.816,  829.229,  830.636,  832.037,  833.433,  834.824,
    836.209,  837.589,  838.964,  840.333,  841.697,  843.056,
    844.41 ,  845.759,  847.102,  848.44 ,  849.773,  851.102,
    852.425,  853.743,  855.056,  856.364,  857.668,  858.966,
    860.26 ,  861.548,  862.833,  864.112,  865.387,  866.657,
    867.922,  869.183,  870.439,  871.691,  872.938,  874.181,
    875.419,  876.653,  877.883,  879.108,  880.33 ,  881.547,
    882.759,  883.968,  885.173,  886.373,  887.57 ,  888.762];

const osramArray:Array<number> = [809, 1005, 2540, 2573, 1306, 1441, 420, 997, 1092, 958, 185]
const hamamatsuArray:Array<number> = [824, 848, 824, 848, 862, 860, 915, 887, 903, 880, 912, 936, 
    948, 991, 1035, 1055, 1096, 1158, 1264, 1352, 1572, 1881, 2508, 3631, 4095, 4095, 4095,
    4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095, 4095,
    4095, 4095, 4095, 4095, 3860, 3260, 2814, 2475, 2221, 2043, 1904, 1768, 1720, 1655, 1615,
    1631, 1567, 1576, 1522, 1532, 1508, 1478, 1487, 1447, 1419, 1367, 1347, 1302, 1304, 1263,
    1262, 1245, 1182, 1196, 1160, 1144, 1150, 1124, 1151, 1096, 1087, 1093, 1073, 1047, 1047,
    1015, 1001, 944, 944, 975, 943, 959, 927, 927, 917, 917, 880, 888, 911, 885, 896, 856, 880,
    879, 869, 881, 831, 840, 860, 816, 840, 832, 816, 816, 816, 832, 832, 807, 811, 792, 792, 776,
    784, 807, 791, 783, 764, 768, 800, 769, 771, 752, 776, 791, 787, 800, 780, 814, 872, 848, 927,
    935, 986, 1008, 1040, 1136, 1100, 1120, 1112, 1096, 1080, 1064, 1016, 1015, 967, 963, 937, 927,
    911, 911, 899, 907, 872, 876, 903, 896, 897, 865, 852, 840, 832, 864, 828, 823, 832, 817, 784,
    800, 824, 776, 788, 775, 775, 796, 728, 756, 736, 736, 764, 720, 735, 727, 723, 727, 727, 704,
    710, 727, 707, 736, 680, 703, 699, 695, 687, 682, 687, 683, 705, 675, 703, 679, 687, 648, 672,
    670, 655, 679, 657, 657, 652, 656, 671, 636, 656, 636, 636, 632, 663, 641, 671, 663, 632, 648,
    673, 632, 688, 632, 657, 643, 649, 640, 645, 636, 656, 647, 651, 688, 632, 659, 632, 647, 656,
    672, 640, 670, 632, 648, 632, 633, 632, 664, 640, 670, 632, 655, 651, 654, 663, 655, 638, 648,
    668, 636, 655, 647, 653, 624, 648, 672, 624, 672, 640, 632, 656, 624];

function getSameFreqValues(): Array<number> {
    const sameOsr:Array<number> = [];
    for (let idx = 0; idx < osramFrequenciesArray.length; idx ++) {
        const dirArr:Array<number> = [];
        for (let hamValue of hamamatsuFrequenciesArray) {
            dirArr.push(Math.abs(osramFrequenciesArray[idx] - hamValue));
        }
        let index = dirArr.findIndex(element => {
            return element === Math.min(...dirArr);
        });
        sameOsr[idx] = hamamatsuFrequenciesArray[index];
    }

    return sameOsr;
}

export {osramFrequenciesArray, hamamatsuFrequenciesArray, osramArray, hamamatsuArray, getSameFreqValues};