import React from "react";
import styles from "./LoginInput.module.scss"

interface Prop {
    field: string;
    value: string;
    onChanging: React.Dispatch<React.SetStateAction<string>>;
}

export default function LoginInfo({field, value, onChanging}:Prop) {

    const onDigit = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChanging(event.target.value);
    }

    return (
        <>
            <label className={styles["input-label"]}>{field}</label>
            <input
                onChange={onDigit}
                value={value}
                className={styles["input-login"]} 
                type="text"
            />
        </>
    )
}