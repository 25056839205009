import LoginInfo from "Components/LoginInput"
import styles from "./Login.module.scss"
import { PiUserCircleFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import React, { useState } from "react";

interface Props {
    centralize:boolean;
}

export default function Login({centralize}:Props) {

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");

    const loginInfo = [
        {
            label: "username",
            state: user,
            setter: setUser
        },
        {
            label: "password",
            state: pass,
            setter: setPass
        }
    ]

    const onSave = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        cleanForm();
    }

    function cleanForm() {
        loginInfo.forEach(content => {
            return content.setter("");
        })
    }

    return (
        <div className={styles["login-container"]}>
            < PiUserCircleFill className={styles.reactIcon}/>
            <h2 className={styles.login}>Login</h2>
            <form onSubmit={onSave} className={styles["form-login"]}>
                {loginInfo.map(content => {
                    return (
                        <LoginInfo
                            onChanging={str => content.setter(str)}
                            value={content.state} 
                            key={content.label} 
                            field={content.label}
                        />
                    )
                })}

                <div className={styles["check-container"]}>
                    <input className={styles.checkbox} type="checkbox" id="check-input"/>
                    <label className={styles["checkbox-label"]} htmlFor="check-input">
                        Remember me
                    </label>
                    <Link className={styles.recover} to="/recover">
                        Forgot password ? 
                    </Link>
                </div>

                <div className={styles["anchor-button-container"]}>
                    <Link className={styles.register} to={"/cadastro"}>
                        Registration
                    </Link>
                    <button className={styles["login-button"]}>
                        Login
                    </button>
                </div>
            </form>
        </div>
    )
}