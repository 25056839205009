// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --dna-usp-color: #f8fafc;
}

.Footer_footer__e62L7 {
  background: var(--dna-usp-color);
  padding: 0.4rem;
  display: flex;
  justify-content: center;
}

.Footer_footer__e62L7 img {
  background-color: var(--dna-usp-color);
  border-radius: 1rem;
  padding: 0.5rem;
  width: 3rem;
}

@media screen and (min-width: 1024px) {
  .Footer_footer__e62L7 img {
    width: 6rem;
  }
}
@media screen and (min-width: 1500px) {
  .Footer_footer__e62L7 img {
    width: 11rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;;AAEA;EACI,gCAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,sCAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;AACJ;;AAEA;EACI;IACI,WAAA;EACN;AACF;AAEA;EACI;IACI,YAAA;EAAN;AACF","sourcesContent":[":root {\n    --dna-usp-color: #f8fafc;\n}\n\n.footer {\n    background: var(--dna-usp-color);\n    padding: 0.4rem;\n    display: flex;\n    justify-content: center;\n}\n\n.footer img {\n    background-color: var(--dna-usp-color);\n    border-radius: 1rem;\n    padding: 0.5rem;\n    width: 3rem;\n}\n\n@media screen and (min-width: 1024px) {\n    .footer img {\n        width: 6rem;\n    }\n} \n\n@media screen and (min-width: 1500px) {\n    .footer img {\n        width: 11rem;\n    }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__e62L7`
};
export default ___CSS_LOADER_EXPORT___;
