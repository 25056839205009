import styles from "./Partners.module.scss"

export default function PartnerBanner() {
    const partnerNames = [
        "usp",
        "ifsc",
        "sebrae",
        "fapesp"
    ]
    return (
        <div className={styles.banner}>
            <h2>Partners</h2>
            <div className={styles["background-banner"]} >
                {partnerNames.map(partner => {
                    return (
                        <div key={partner} className={styles["container-image"]}>
                            <img
                                className={styles.partners} 
                                src={`./${partner}-logo.svg`} 
                                alt={`${partner} logo`} 
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}