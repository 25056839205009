// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/stars.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginBanner_cont__lXXWJ {
  display: block;
}

.LoginBanner_banner-container__R5QbM {
  display: none;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: repeat;
  background-size: contain;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .LoginBanner_banner-container__R5QbM {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: var(--main-font);
    color: var(--main-white);
  }
  .LoginBanner_sec-container__xGqyj {
    display: flex;
  }
  .LoginBanner_optikai-merchan__fnkBU {
    font-size: 2rem;
  }
  .LoginBanner_arrow__lxjPS {
    margin: 3rem;
    width: 6rem;
    height: 6rem;
  }
  .LoginBanner_signUp__8vNOt {
    font-size: 3rem;
  }
  .LoginBanner_signUp__sec__05cwO {
    font-size: 2rem;
    margin: 3rem;
  }
}
@media screen and (min-width: 1500px) {
  .LoginBanner_optikai-merchan__fnkBU {
    font-size: 3rem;
    padding: 0 5rem;
  }
  .LoginBanner_arrow__lxjPS {
    margin: 3rem;
    width: 10rem;
    height: 10rem;
  }
  .LoginBanner_signUp__8vNOt {
    font-size: 4rem;
  }
  .LoginBanner_signUp__sec__05cwO {
    font-size: 3rem;
    margin: 3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LoginBanner/LoginBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,yDAAA;EACA,yBAAA;EACA,wBAAA;EACA,WAAA;AACJ;;AAEA;EAEI;IACI,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,kBAAA;IACA,6BAAA;IACA,wBAAA;EAAN;EAGE;IACI,aAAA;EADN;EAKE;IACI,eAAA;EAHN;EAME;IACI,YAAA;IACA,WAAA;IACA,YAAA;EAJN;EAOE;IACI,eAAA;EALN;EAMM;IACI,eAAA;IACA,YAAA;EAJV;AACF;AAQA;EAEI;IACI,eAAA;IACA,eAAA;EAPN;EAUE;IACI,YAAA;IACA,YAAA;IACA,aAAA;EARN;EAWE;IACI,eAAA;EATN;EAUM;IACI,eAAA;IACA,YAAA;EARV;AACF","sourcesContent":[".cont {\n    display: block;\n}\n\n.banner-container {\n    display: none;\n    background-image: url(\"/assets/stars.jpg\");\n    background-repeat: repeat;\n    background-size: contain;\n    width: 100%;\n}\n\n@media screen and (min-width:1024px) {\n\n    .banner-container {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        text-align: center;\n        font-family: var(--main-font);\n        color: var(--main-white);\n    }\n\n    .sec-container {\n        display: flex;\n        \n    }\n\n    .optikai-merchan {\n        font-size: 2rem;\n    }\n\n    .arrow {\n        margin: 3rem;\n        width: 6rem;\n        height: 6rem;\n    }\n\n    .signUp {\n        font-size: 3rem;\n        &__sec {\n            font-size: 2rem;\n            margin: 3rem;\n        }\n    }\n}\n\n@media screen and (min-width:1500px) {\n\n    .optikai-merchan {\n        font-size: 3rem;\n        padding: 0 5rem;\n    }\n\n    .arrow {\n        margin: 3rem;\n        width: 10rem;\n        height: 10rem;\n    }\n\n    .signUp {\n        font-size: 4rem;\n        &__sec {\n            font-size: 3rem;\n            margin: 3rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": `LoginBanner_cont__lXXWJ`,
	"banner-container": `LoginBanner_banner-container__R5QbM`,
	"sec-container": `LoginBanner_sec-container__xGqyj`,
	"optikai-merchan": `LoginBanner_optikai-merchan__fnkBU`,
	"arrow": `LoginBanner_arrow__lxjPS`,
	"signUp": `LoginBanner_signUp__8vNOt`,
	"signUp__sec": `LoginBanner_signUp__sec__05cwO`
};
export default ___CSS_LOADER_EXPORT___;
