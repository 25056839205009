// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --button-color: #6278f7;
  --hover-button-color: #7288ff;
  --hover-color: #95ffd4;
}

.Button_button__Y\\+F0s {
  color: var(--main-white);
  background-color: var(--button-color);
  font-family: var(--main-font);
  cursor: pointer;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0.75rem;
  margin-top: 2rem;
  border-radius: 1.5rem;
  border: none;
}
.Button_button__Y\\+F0s:hover {
  color: var(--hover-color);
  background-color: var(--hover-button-color);
}

@media screen and (min-width: 1024px) {
  .Button_button__Y\\+F0s {
    font-size: 1.1rem;
    padding: 0.8rem;
  }
}
@media screen and (min-width: 1500px) {
  .Button_button__Y\\+F0s {
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,6BAAA;EACA,sBAAA;AACJ;;AAEA;EACI,wBAAA;EACA,qCAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,qBAAA;EACA,YAAA;AACJ;AAAI;EACI,yBAAA;EACA,2CAAA;AAER;;AAEA;EACI;IACI,iBAAA;IACA,eAAA;EACN;AACF;AAEA;EACI;IACI,iBAAA;IACA,aAAA;IACA,mBAAA;EAAN;AACF","sourcesContent":[":root {\n    --button-color: #6278f7;\n    --hover-button-color: #7288ff;\n    --hover-color: #95ffd4;\n}\n\n.button {\n    color: var(--main-white);\n    background-color: var(--button-color);\n    font-family: var(--main-font);\n    cursor: pointer;\n    font-weight: 600;\n    font-size: 0.75rem;\n    padding: 0.75rem;\n    margin-top: 2rem;\n    border-radius: 1.5rem;\n    border: none;\n    &:hover {\n        color: var(--hover-color);\n        background-color: var(--hover-button-color);\n    }\n}\n\n@media screen and (min-width: 1024px) {\n    .button {\n        font-size: 1.1rem;\n        padding: 0.8rem;\n    }\n}\n\n@media screen and (min-width: 1500px) {\n    .button {\n        font-size: 1.5rem;\n        padding: 1rem;\n        border-radius: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__Y+F0s`
};
export default ___CSS_LOADER_EXPORT___;
