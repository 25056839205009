import SampleStats from "Components/SampleStats"
import styles from "./MobileDashInfo.module.scss"
import SpectralDist from "Components/SpectralDist"
import DonutChart from "Components/DonutChart"

export default function MobileDashInfo() {
    return (
        <div className={styles.dashboards}>
            <SampleStats/>
            <SpectralDist/>
            <DonutChart/>
        </div>
    )
}