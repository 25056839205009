// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SampleStats_statsContainer__yVyhL {
  display: flex;
  flex-direction: column;
  font-family: var(--main-font);
  text-align: center;
  align-items: center;
  margin: 0 1rem;
  width: 90%;
  margin-bottom: 3rem;
}

.SampleStats_statsText__Sq6DH {
  margin-bottom: 3rem;
  font-size: 1.2rem;
}

.SampleStats_individualStats__XnKeL {
  border: 0.1rem solid;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 2rem;
  border-top: none;
}
.SampleStats_individualStats__border-top__4Cq5- {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border: 0.1rem solid;
}
.SampleStats_individualStats__border-bottom__qTaME {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.SampleStats_apiInfo__V6oZj {
  text-decoration: none;
  font-weight: 600;
  color: var(--main-dark-color);
}

.SampleStats_nameContainer__wjuUH {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .SampleStats_statsContainer__yVyhL {
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/SampleStats/SampleStats.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,aAAA;EACA,WAAA;EACA,8BAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAAI;EACI,4BAAA;EACA,6BAAA;EACA,oBAAA;AAER;AAAI;EACI,+BAAA;EACA,gCAAA;AAER;;AAEA;EACI,qBAAA;EACA,gBAAA;EACA,6BAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI;IACI,UAAA;EACN;AACF","sourcesContent":[".statsContainer {\n    display: flex;\n    flex-direction: column;\n    font-family: var(--main-font);\n    text-align: center;\n    align-items: center;\n    margin: 0 1rem;\n    width: 90%;\n    margin-bottom: 3rem;\n}\n\n.statsText {\n    margin-bottom: 3rem;\n    font-size: 1.2rem;\n}\n\n.individualStats {\n    border: 0.1rem solid;\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    padding: 1rem;\n    margin: 0 2rem;\n    border-top: none;\n    &__border-top {\n        border-top-left-radius: 1rem;\n        border-top-right-radius: 1rem;\n        border: 0.1rem solid;\n    }\n    &__border-bottom {\n        border-bottom-left-radius: 1rem;\n        border-bottom-right-radius: 1rem;\n    }\n}\n\n.apiInfo {\n    text-decoration: none;\n    font-weight: 600;\n    color: var(--main-dark-color);\n}\n\n.nameContainer {\n    width: 100%;\n}\n\n@media screen and (min-width: 1024px) {\n    .statsContainer {\n        width: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsContainer": `SampleStats_statsContainer__yVyhL`,
	"statsText": `SampleStats_statsText__Sq6DH`,
	"individualStats": `SampleStats_individualStats__XnKeL`,
	"individualStats__border-top": `SampleStats_individualStats__border-top__4Cq5-`,
	"individualStats__border-bottom": `SampleStats_individualStats__border-bottom__qTaME`,
	"apiInfo": `SampleStats_apiInfo__V6oZj`,
	"nameContainer": `SampleStats_nameContainer__wjuUH`
};
export default ___CSS_LOADER_EXPORT___;
