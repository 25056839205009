// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DonutChart_donutContainer__SDYSP {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DonutChart_donutTitle__k8sK1 {
  font-family: var(--main-font);
  font-size: 1.2rem;
}

@media screen and (min-width: 1024px) {
  .DonutChart_donutContainer__SDYSP {
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/DonutChart/DonutChart.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAGA;EACI,6BAAA;EACA,iBAAA;AAAJ;;AAGA;EACI;IACI,UAAA;EAAN;AACF","sourcesContent":[".donutContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.donutTitle {\n    font-family: var(--main-font);\n    font-size: 1.2rem;\n}\n\n@media screen and (min-width: 1024px)  {\n    .donutContainer {\n        width: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"donutContainer": `DonutChart_donutContainer__SDYSP`,
	"donutTitle": `DonutChart_donutTitle__k8sK1`
};
export default ___CSS_LOADER_EXPORT___;
