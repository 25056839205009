import styles from "./StatisticalInfo.module.scss"
import classNames from "classnames";

export default function StatisticalInfo() {

    const ArrInfo = [
        {
            content: "Mean Value [μ]",
            radiusBottom: false,
            radiusTop: true,
            value: 1.2
        },
        {
            content: "Standard Deviation [σ]",
            radiusBottom: false,
            radiusTop: false,
            value: 0.1
        },
        {
            content: "Skewness [γ]",
            radiusBottom: true,
            radiusTop: false,
            value: -2
        }
    ];

    return (
        <div className={styles.reportContainer}>
             <h2 className={styles.reportText}>Statistical information</h2>
            {ArrInfo.map((reportInfo, index) => {
                return (
                    <div 
                        key={reportInfo.content} 
                        className={classNames({
                            [styles.individualReport]: true,
                            [styles["individualReport__border-top"]]: reportInfo.radiusTop,
                            [styles["individualReport__border-bottom"]]: reportInfo.radiusBottom
                        })}>
                        <h3 className={styles.nameContainer}>
                            {reportInfo.content}
                        </h3>
                        
                        <h3 className={styles.nameContainer}>
                            {reportInfo.value}
                        </h3>
                    </div>
                )
            })}
        </div>
    )    
}