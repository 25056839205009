import { Link } from "react-router-dom"
import styles from "./DashBoard.module.scss"
import MobileDashInfo from "./MobileDashInfo"
import DeskDashInfo from "./DeskdashInfo"

export default function DashBoard() {

    const asideArray = [
        {
            content: "Menu",
            to: "/"
        },
        {
            content: "Contact",
            to: "/contact"
        },
        {
            content:"Information",
            to: "/contact"
        },
        {
            content: "Report generation",
            to: "/contact"
        },
        {
            content: "Technical",
            to: "/contact"
        }
    ]

    return (
        <div className={styles.dashContainer}>
            <aside className={styles.dashAside}>
                <ul className={styles.dashList}>
                    {asideArray.map(info => {
                        return (
                            <li className={styles.dashListElement} key={info.content}>
                                <Link className={styles.dashLink} to={info.to}>
                                    {info.content}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </aside>

            <MobileDashInfo/>
            <DeskDashInfo/>
        </div>
    )
}