import Chart from "react-google-charts";
import { getSameFreqValues, hamamatsuArray, hamamatsuFrequenciesArray, osramArray } from "./data";
import styles from "./SpectralDist.module.scss"

export default function SpectralDist() {
    const osramName: string = "Osram Spectrum";
    const hamamatsuName: string = "Hamamatsu Spectrum";

    function preProcessData(arrayDatax: Array<number>, 
        arrayDatay: Array<number>) {
        let counter:number = 0;
        const sameOsr = getSameFreqValues();
        
        const data: (string | number | null)[][] = [
            ["frequencies", osramName, hamamatsuName]
        ]

        for (let idx = 0; idx < arrayDatay.length; idx++) {
            if (sameOsr.includes(arrayDatax[idx])) {
                data.push([arrayDatax[idx], osramArray[counter], arrayDatay[idx]]);
                counter++;
            } else {
                data.push([arrayDatax[idx], null, arrayDatay[idx]]);
            }
        }

        return data;
    }

    const options = {
        title: "",
        curveType: "function",
        legend: { position: "bottom" },
        backgroundColor:"transparent"
      };

    return (

        <div className={styles.spectralContainer}>
        <h2 className={styles.chartTitle}> Sample Spectrum </h2>

        <Chart
            width="100%"
            height="400px"
            chartType="LineChart"
            data={preProcessData(hamamatsuFrequenciesArray, hamamatsuArray)}
            options={options}
        />
        </div>
    )
}