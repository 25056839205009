import classNames from "classnames"
import styles from "./SampleStats.module.scss"
import { Link } from "react-router-dom"

export default function SampleStats() {

    const arraySpecies = [
        {
            specie: "Especial",
            number: 12,
            radiusBottom: false,
            radiusTop: true,
            sample: "sample",
            to: "/report"
        },
        {
            specie: "Gourmet",
            number: 15,
            radiusBottom: false,
            radiusTop: false,
            sample: "sample",
            to: "/report"
        },
        {
            specie: "Riado",
            number: 20,
            radiusBottom: false,
            radiusTop: false,
            sample: "sample",
            to: "/report"

        },
        {
            specie: "Rio",
            number: 1,
            radiusBottom: true,
            radiusTop: false,
            sample: "sample",
            to: "/report"
        }
    ]

    return (
        <div className={styles.statsContainer}>
             <h2 className={styles.statsText}>Status</h2>
            {arraySpecies.map((specieInfo, index) => {
                return (
                    <div 
                        key={specieInfo.specie} 
                        className={classNames({
                            [styles.individualStats]: true,
                            [styles["individualStats__border-top"]]: specieInfo.radiusTop,
                            [styles["individualStats__border-bottom"]]: specieInfo.radiusBottom
                        })}>
                        <h3 className={styles.nameContainer}>
                            {specieInfo.number}
                        </h3>

                        <h3 className={styles.nameContainer}>
                            <Link 
                                className={styles.apiInfo} 
                                to={specieInfo.to}
                            >
                                {specieInfo.sample + 
                                " " + 
                                ( index+1 )}
                            </Link>
                        </h3>

                        <h3 className={styles.nameContainer}>
                            {specieInfo.specie}
                        </h3>
                    </div>
                )
            })}
        </div>
    )
}