import styles from "./Header.module.scss"
import { MdOutlineShoppingBag } from "react-icons/md"; 
import {BsPersonCircle} from "react-icons/bs"; 
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalLogin from "Components/ModalLogin";
import ModalRegister from "Components/ModalRegister";
import http, { getUserList } from "../../http"
import { useQuery } from "react-query";

const Header = () => {

    const [modalOpen, setModalOpen] = useState(false); 
    const [registerModalOpen, setRegisterModalOpen] = useState(false); 

    const token = sessionStorage.getItem("token");
    const [ userLogin, setUserLogin ] = useState<boolean>(token != null);
    const navigate = useNavigate();

    const onLogin = () => {
        setModalOpen(false);
        setUserLogin(true);
    }

    const createRegister = () => {
        setRegisterModalOpen(false);
    }

    const {data: userList } = useQuery({
        queryKey: ["allLogedUsers"],
        queryFn: () => getUserList()
    })

    const logout = () => {
        const email = sessionStorage.getItem("email");
        const user = userList?.find(user => {
            return user.email === email
        })

        http.delete(`login/${user!.id}`)
        .then(() => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("email");
            setUserLogin(false);
            navigate("/");
            alert("Logout done successfully");
        })
        .catch(error => {
            if (error?.response?.data?.message) {
                alert(error.response.data.message);
            } else {
                alert("Unexpected error ocurred, please contact the support team");
            }
        })
    }

    const arr = [
        {
            content: "Data",
            to: "/data"
        },
        {
            content: "Contact",
            to: "/contact"
        },
    ]

    return (
        <header className={styles.header}>
            <Link className={styles.tagLink} to="/">
                <img className={styles.imgLogo} src="image2vector.svg" alt="logo da optikai" />
            </Link>

            <div className={styles.linkContainer}>
                {arr.map(info => {
                    return (
                        <div
                            className={styles.borderLinks}
                            key={info.content}>
                            <Link
                                className={styles.persLink} 
                                to={info.to}
                            >
                                {info.content}
                            </Link>
                        </div>
                    )
                })}
            </div>

            <div className={styles.icons}>

                <div className={styles.icon}>
                    <Link className={styles.shopLink} to="/shop">
                        <MdOutlineShoppingBag className={styles.shoppingBag} />
                        <p className={styles.iconParagraph}>Shop bag</p>
                    </Link>
                </div>

                <div 
                    onClick={userLogin
                        ? logout
                        : () => setModalOpen(true)
                    } 
                    className={styles.icon}
                >
                    <BsPersonCircle className={styles.loginIcon} />
                    <p className={styles.iconParagraph}>
                        {userLogin ? "Logout": "Login"}
                    </p>
                </div>
            </div>

            <ModalLogin 
                title="Login"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onRegister={() => {
                    setRegisterModalOpen(true);
                    setModalOpen(false);
                }}
                onLogin={onLogin}
            />
            <ModalRegister 
                title="Register"
                open={registerModalOpen}
                onClose={() => setRegisterModalOpen(false)}
                onRegister={createRegister}
            />


        </header>
    )
}

export default Header;