import classNames from "classnames";
import styles from "./TextField.module.scss"

interface Props {
    label: string;
    type: string;
    placeHolder: string;
    value: string;
    onChanging: React.Dispatch<React.SetStateAction<string>>;
    boxShadow: boolean;
}

export default function TextField({
    label, 
    value, 
    onChanging, 
    placeHolder, 
    type="text",
    boxShadow
    }: Props) {

    const messageInput = label.toLocaleLowerCase() === "message" ? true : false;

    const onDigit = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChanging(event.target.value);
    }

    return (
        <div className={classNames({
            [styles["input-text"]]: true,
            [styles["input-text__message"]]: messageInput
        })}>
            <label className={styles.label} >{label}</label>
            <input 
                required
                value={value}
                onChange={onDigit}
                className={classNames({
                    [styles.input]:true,
                    [styles.input__message]: messageInput,
                    [styles.input__boxShadow]: boxShadow
                })} 
                type={type}
                placeholder={placeHolder}
            />
        </div>
    )
}