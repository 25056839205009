// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticalInfo_reportContainer__531p8 {
  display: flex;
  flex-direction: column;
  font-family: var(--main-font);
  text-align: center;
  align-items: center;
  margin: 1rem 0;
  margin-bottom: 2rem;
  width: 60%;
}

.StatisticalInfo_reportText__TeTDb {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.StatisticalInfo_individualReport__zo-BG {
  border: 0.1rem solid;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 2rem;
  border-top: none;
}
.StatisticalInfo_individualReport__border-top__jmaay {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border: 0.1rem solid;
}
.StatisticalInfo_individualReport__border-bottom__shRKY {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.StatisticalInfo_apiInfo__wZw06 {
  text-decoration: none;
  font-weight: 600;
  color: var(--main-dark-color);
}

.StatisticalInfo_nameContainer__1cl7x {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/StatisticalInfo/StatisticalInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,UAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,aAAA;EACA,WAAA;EACA,8BAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAAI;EACI,4BAAA;EACA,6BAAA;EACA,oBAAA;AAER;AAAI;EACI,+BAAA;EACA,gCAAA;AAER;;AAEA;EACI,qBAAA;EACA,gBAAA;EACA,6BAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".reportContainer {\n    display: flex;\n    flex-direction: column;\n    font-family: var(--main-font);\n    text-align: center;\n    align-items: center;\n    margin: 1rem 0;\n    margin-bottom: 2rem;\n    width: 60%;\n}\n\n.reportText {\n    margin-bottom: 2rem;\n    font-size: 1.5rem;\n}\n\n.individualReport {\n    border: 0.1rem solid;\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    padding: 1rem;\n    margin: 0 2rem;\n    border-top: none;\n    &__border-top {\n        border-top-left-radius: 1rem;\n        border-top-right-radius: 1rem;\n        border: 0.1rem solid;\n    }\n    &__border-bottom {\n        border-bottom-left-radius: 1rem;\n        border-bottom-right-radius: 1rem;\n    }\n}\n\n.apiInfo {\n    text-decoration: none;\n    font-weight: 600;\n    color: var(--main-dark-color);\n}\n\n.nameContainer {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportContainer": `StatisticalInfo_reportContainer__531p8`,
	"reportText": `StatisticalInfo_reportText__TeTDb`,
	"individualReport": `StatisticalInfo_individualReport__zo-BG`,
	"individualReport__border-top": `StatisticalInfo_individualReport__border-top__jmaay`,
	"individualReport__border-bottom": `StatisticalInfo_individualReport__border-bottom__shRKY`,
	"apiInfo": `StatisticalInfo_apiInfo__wZw06`,
	"nameContainer": `StatisticalInfo_nameContainer__1cl7x`
};
export default ___CSS_LOADER_EXPORT___;
