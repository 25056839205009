// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashBoard_dashAside__ooJXw {
  margin: 2rem 0;
  width: 30%;
}

.DashBoard_dashContainer__5-IrD {
  display: flex;
}

.DashBoard_dashListElement__UpqfJ {
  font-family: var(--main-font);
  font-weight: 500;
  padding: 1rem;
}

.DashBoard_dashLink__ek9Kx {
  text-decoration: none;
  color: var(--main-dark-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard/DashBoard.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,UAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAGA;EACI,6BAAA;EACA,gBAAA;EACA,aAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,6BAAA;AAAJ","sourcesContent":[".dashAside {\n    margin: 2rem 0;\n    width: 30%;\n}\n\n.dashContainer {\n    display: flex;\n    // justify-content: space-between;\n}\n\n.dashListElement {\n    font-family: var(--main-font);\n    font-weight: 500;\n    padding: 1rem;\n}\n\n.dashLink {\n    text-decoration: none;\n    color: var(--main-dark-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashAside": `DashBoard_dashAside__ooJXw`,
	"dashContainer": `DashBoard_dashContainer__5-IrD`,
	"dashListElement": `DashBoard_dashListElement__UpqfJ`,
	"dashLink": `DashBoard_dashLink__ek9Kx`
};
export default ___CSS_LOADER_EXPORT___;
