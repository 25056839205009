// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --input-color: #e4e4e4;
}

.Contact_input-container__gxsWS {
  align-items: center;
  text-align: center;
  margin: 2rem 0;
  padding: 0 3rem;
}

.Contact_contact__7FJBr {
  width: 100%;
  font-family: var(--main-font);
  font-size: 1.25rem;
  padding: 0.5rem 0;
  margin-bottom: 0.4rem;
}

.Contact_div-container__1\\+fiE {
  background-color: var(--input-color);
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 2rem;
}

.Contact_input-container__gxsWS .Contact_div-container__1\\+fiE form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (min-width: 1024px) {
  .Contact_contact__7FJBr {
    font-size: 2.1rem;
    margin-bottom: 1.75rem;
  }
  .Contact_input-container__gxsWS {
    padding: 0 5rem;
  }
}
@media screen and (min-width: 1500px) {
  .Contact_contact__7FJBr {
    font-size: 3rem;
    margin-bottom: 2.5rem;
  }
  .Contact_input-container__gxsWS {
    padding: 0 6rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/Contact.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;EACA,6BAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,oCAAA;EACA,4BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,eAAA;EACA,6BAAA;AACJ;;AAEA;EACI;IACI,iBAAA;IACA,sBAAA;EACN;EAEE;IACI,eAAA;EAAN;AACF;AAGA;EACI;IACI,eAAA;IACA,qBAAA;EADN;EAIE;IACI,eAAA;EAFN;AACF","sourcesContent":[":root {\n    --input-color: #e4e4e4;\n}\n\n.input-container {\n    align-items: center;\n    text-align: center;\n    margin: 2rem 0;\n    padding: 0 3rem;\n}\n\n.contact {\n    width: 100%;\n    font-family: var(--main-font);\n    font-size: 1.25rem;\n    padding: 0.5rem 0;\n    margin-bottom: 0.4rem;\n}\n\n.div-container {\n    background-color: var(--input-color);\n    padding: 1rem 2rem 2rem 2rem;\n    border-radius: 2rem;\n}\n\n.input-container .div-container form {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n}\n\n@media screen and (min-width: 1024px) {\n    .contact {\n        font-size: 2.1rem;\n        margin-bottom: 1.75rem;\n    }\n\n    .input-container {\n        padding: 0 5rem;    \n    }\n}\n\n@media screen and (min-width: 1500px) {\n    .contact {\n        font-size: 3rem;\n        margin-bottom: 2.5rem;\n    }\n\n    .input-container {\n        padding: 0 6rem;    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-container": `Contact_input-container__gxsWS`,
	"contact": `Contact_contact__7FJBr`,
	"div-container": `Contact_div-container__1+fiE`
};
export default ___CSS_LOADER_EXPORT___;
