// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --component-color: #1400eb;
  --close-button-color: #002F52;
}

.Modal_modalBackground__JYAO3 {
  position: fixed;
  left: 0rem;
  right: 0rem;
  top: 0rem;
  bottom: 0rem;
  background: rgba(101, 101, 101, 0.85);
}

.Modal_modalMainWindow__98ljA {
  position: fixed;
  padding: 1rem;
  background: var(--main-white);
  box-shadow: 0.25rem 0.25rem 1.5rem -0.25rem rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Modal_modalButton__joEsA {
  background: transparent;
  cursor: pointer;
  border: none;
  color: var(--close-button-color);
}

.Modal_modalTitleWrapper__WHqGP {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Modal_modalTitle__tuAjQ {
  line-height: 3rem;
  font-weight: 700;
  font-size: 2rem;
  color: var(--component-color);
}

@media screen and (min-width: 1024px) {
  .Modal_modalMainWindow__98ljA {
    padding: 2rem;
  }
  .Modal_modalTitle__tuAjQ {
    font-size: 3rem;
  }
}
@media screen and (min-width: 1024px) {
  .Modal_modalMainWindow__98ljA {
    padding: 3rem;
  }
  .Modal_modalTitle__tuAjQ {
    font-size: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,6BAAA;AACJ;;AAEA;EACI,eAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;EACA,qCAAA;AACJ;;AAEA;EACI,eAAA;EACA,aAAA;EACA,6BAAA;EACA,+DAAA;EACA,qBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACJ;;AAEA;EACI,uBAAA;EACA,eAAA;EACA,YAAA;EACA,gCAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,6BAAA;AACJ;;AAEA;EACI;IACI,aAAA;EACN;EAEE;IACI,eAAA;EAAN;AACF;AAGA;EACI;IACI,aAAA;EADN;EAIE;IACI,eAAA;EAFN;AACF","sourcesContent":[":root {\n    --component-color: #1400eb;\n    --close-button-color: #002F52;\n}\n\n.modalBackground {\n    position: fixed;\n    left: 0rem;\n    right: 0rem;\n    top: 0rem;\n    bottom: 0rem;\n    background: rgba(101, 101, 101, 0.85);   \n}\n\n.modalMainWindow {\n    position: fixed;\n    padding: 1rem;\n    background: var(--main-white);\n    box-shadow: 0.25rem 0.25rem 1.5rem -0.25rem rgba(0, 0, 0, 0.15);\n    border-radius: 1.5rem;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.modalButton {\n    background: transparent;\n    cursor: pointer;\n    border: none;\n    color: var(--close-button-color);\n}\n\n.modalTitleWrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.modalTitle {\n    line-height: 3rem;\n    font-weight: 700;\n    font-size: 2rem;\n    color: var(--component-color);\n}\n\n@media screen and (min-width: 1024px) {\n    .modalMainWindow {\n        padding: 2rem;\n    }\n\n    .modalTitle {\n        font-size: 3rem;\n    }\n}\n\n@media screen and (min-width: 1024px) {\n    .modalMainWindow {\n        padding: 3rem;\n    }\n\n    .modalTitle {\n        font-size: 4rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackground": `Modal_modalBackground__JYAO3`,
	"modalMainWindow": `Modal_modalMainWindow__98ljA`,
	"modalButton": `Modal_modalButton__joEsA`,
	"modalTitleWrapper": `Modal_modalTitleWrapper__WHqGP`,
	"modalTitle": `Modal_modalTitle__tuAjQ`
};
export default ___CSS_LOADER_EXPORT___;
