// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginInput_input-label__SMCTW {
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--main-white);
  margin-bottom: 0.3rem;
}

.LoginInput_input-login__u8FVj {
  width: 10rem;
  border-radius: 2rem;
  font-size: 0.7rem;
  font-family: var(--main-font);
  border: solid var(--main-dark-color) 0.01rem;
  margin-bottom: 0.7rem;
  padding: 0.1rem;
}

@media screen and (min-width: 1024px) {
  .LoginInput_input-label__SMCTW {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .LoginInput_input-login__u8FVj {
    width: 20rem;
    padding: 0.5rem;
    border-radius: 3rem;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1500px) {
  .LoginInput_input-label__SMCTW {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .LoginInput_input-login__u8FVj {
    width: 25rem;
    padding: 0.8rem;
    border-radius: 3rem;
    font-size: 1.2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LoginInput/LoginInput.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,6BAAA;EACA,4CAAA;EACA,qBAAA;EACA,eAAA;AACJ;;AAEA;EACI;IACI,eAAA;IACA,mBAAA;EACN;EAEE;IACI,YAAA;IACA,eAAA;IACA,mBAAA;IACA,eAAA;EAAN;AACF;AAGA;EACI;IACI,iBAAA;IACA,mBAAA;EADN;EAIE;IACI,YAAA;IACA,eAAA;IACA,mBAAA;IACA,iBAAA;EAFN;AACF","sourcesContent":[".input-label {\n    font-family: var(--main-font);\n    font-weight: 500;\n    font-size: 0.8rem;\n    color: var(--main-white);\n    margin-bottom: 0.3rem;\n}\n\n.input-login {\n    width: 10rem;\n    border-radius: 2rem;\n    font-size: 0.7rem;\n    font-family: var(--main-font);\n    border: solid var(--main-dark-color) 0.01rem;\n    margin-bottom: 0.7rem;\n    padding: 0.1rem;\n}\n\n@media screen and (min-width: 1024px) {\n    .input-label {\n        font-size: 1rem;\n        margin-bottom: 1rem;\n    }\n\n    .input-login {\n        width: 20rem;\n        padding: 0.5rem;\n        border-radius: 3rem;\n        font-size: 1rem;\n    }\n}\n\n@media screen and (min-width: 1500px) {\n    .input-label {\n        font-size: 1.4rem;\n        margin-bottom: 1rem;\n    }\n\n    .input-login {\n        width: 25rem;\n        padding: 0.8rem;\n        border-radius: 3rem;\n        font-size: 1.2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-label": `LoginInput_input-label__SMCTW`,
	"input-login": `LoginInput_input-login__u8FVj`
};
export default ___CSS_LOADER_EXPORT___;
