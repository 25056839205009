import StatisticalInfo from "Components/StatisticalInfo";
import styles from "./Report.module.scss"
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import PdfMaker from "Components/PdfMaker";

export default function Report() {

    const style = StyleSheet.create({
        viewer: {
            width: "60%",
            height: window.innerHeight
        }
    });

    return (
        <div className={styles.downloadContainer}>
            <StatisticalInfo/>
            <PDFViewer style={style.viewer}>
                <PdfMaker/>
            </PDFViewer>
        </div>        
    )
}