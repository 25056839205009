// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpectralDist_spectralContainer__mugrv {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
}

.SpectralDist_chartTitle__dG-eF {
  font-family: var(--main-font);
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/SpectralDist/SpectralDist.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,iBAAA;AACJ","sourcesContent":[".spectralContainer {\n    margin-top: 1rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    margin-bottom: 3rem;\n}\n\n.chartTitle {\n    font-family: var(--main-font);\n    font-size: 1.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spectralContainer": `SpectralDist_spectralContainer__mugrv`,
	"chartTitle": `SpectralDist_chartTitle__dG-eF`
};
export default ___CSS_LOADER_EXPORT___;
