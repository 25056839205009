// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeskDashInfo_deskDashContainer__gISdc {
  display: none;
}

@media screen and (min-width: 1024px) {
  .DeskDashInfo_deskDashContainer__gISdc {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    margin: 2rem 0;
  }
  .DeskDashInfo_deskFlexWrapper__nXERB {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard/DeskdashInfo/DeskDashInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI;IACI,aAAA;IACA,sBAAA;IACA,UAAA;IACA,uBAAA;IACA,cAAA;EACN;EAEE;IACI,aAAA;EAAN;AACF","sourcesContent":[".deskDashContainer {\n    display: none;\n}\n\n@media screen and (min-width:1024px) {\n    .deskDashContainer {\n        display: flex;\n        flex-direction: column;\n        width: 70%;\n        justify-content: center;\n        margin: 2rem 0;\n    }\n\n    .deskFlexWrapper {\n        display: flex;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deskDashContainer": `DeskDashInfo_deskDashContainer__gISdc`,
	"deskFlexWrapper": `DeskDashInfo_deskFlexWrapper__nXERB`
};
export default ___CSS_LOADER_EXPORT___;
