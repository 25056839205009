import Footer from "Components/Footer";
import Header from "Components/Header";
import About from "pages/About";
import Contact from "pages/Contact";
import Report from "pages/Report";
import DashBoard from "pages/DashBoard";
import Data from "pages/Data";
import Login from "pages/Login";
import RegisterPage from "pages/Register";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default function AppRouter() {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<About/>}/>
                <Route path="/data" element={<Data/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/dashboard" element={<DashBoard/>}/>
                <Route path="/report" element={<Report/>}/>
                <Route path="/login" element={<Login centralize={false} />}/>
                <Route path="/cadastro" element={<RegisterPage centralize={true}/>}/>
            </Routes>
            <Footer/>
        </Router>
    )
}