import styles from "./LoginBanner.module.scss"
import { BiSolidRightArrow } from "react-icons/bi";

export default function LoginBanner() {
    return (
        <div className={styles["banner-container"]}>
            <h2 className={styles["signUp"]}>Sign up now !</h2>

            <h2 className={styles["signUp__sec"]}>
                Fill the forms with your data and jump into our team
            </h2>
            <BiSolidRightArrow className={styles.arrow}/>
            
            <h2 className={styles["signUp__sec"]}>
                Get in and discover our work
            </h2>

            <h2 className={styles["optikai-merchan"]}>
                OptikAI the best solution to your coffee's quality
            </h2>
        </div>
    )
}