import React from "react"
import styles from "./Main.module.scss"
import PartnerBanner from "./Partners"

export default function Main() {
    return(
    <React.Fragment>
        <div className={styles.main}>
            <div className={styles["text-content"]}>
                <h1>
                    Goals
                </h1>
                <p>
                    building web site, you can acces the application prototype in optikai.com.br:3000
                </p>
            </div>
            <img
                className={styles["green-coffee"]}
                src="./pe_cafe_square.jpeg" 
                alt="imagem do café" 
            />

        </div>
        <PartnerBanner/>
    </React.Fragment>
    )
}