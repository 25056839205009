// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileDashInfo_dashboards__ctDoJ {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 70%;
}

@media screen and (min-width: 1024px) {
  .MobileDashInfo_dashboards__ctDoJ {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard/MobileDashInfo/MobileDashInfo.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI;IACI,aAAA;EACN;AACF","sourcesContent":[".dashboards {\n    display: flex;\n    flex-direction: column;\n    margin-top: 1rem;\n    width: 70%;\n}\n\n@media screen and (min-width: 1024px) {\n    .dashboards {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboards": `MobileDashInfo_dashboards__ctDoJ`
};
export default ___CSS_LOADER_EXPORT___;
