import SpectralDist from "Components/SpectralDist"
import styles from "./DeskDashInfo.module.scss"
import SampleStats from "Components/SampleStats"
import DonutChart from "Components/DonutChart"

export default function DeskDashInfo() {
    return (
        <div className={styles.deskDashContainer}>
            <div className={styles.deskFlexWrapper}>
                <SampleStats/>
                <DonutChart/>
            </div>
            <SpectralDist/>
        </div>
    )
}