// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalRegister_loginImage__v6HFp {
  width: 15rem;
  border-radius: 2rem;
}

.ModalRegister_registerContainer__8Q50d {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem 0;
}

.ModalRegister_formModalContainer__eUrTD {
  display: flex;
  flex-direction: column;
}

.ModalRegister_formStyle__f88To {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalRegister_registerButton__cxZZk {
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  background-color: var(--main-color);
  cursor: pointer;
}
.ModalRegister_registerButton__bottom__VYVAf {
  margin-bottom: 1rem;
}

.ModalRegister_hLine__1tfF8 {
  width: 30rem;
}

@media screen and (min-width: 1024px) {
  .ModalRegister_registerContainer__8Q50d {
    flex-direction: row;
    gap: 7rem;
  }
  .ModalRegister_registerButton__cxZZk {
    font-size: 1.25rem;
  }
  .ModalRegister_loginImage__v6HFp {
    width: 25rem;
  }
}
@media screen and (min-width: 1500px) {
  .ModalRegister_registerContainer__8Q50d {
    gap: 12rem;
  }
  .ModalRegister_registerButton__cxZZk {
    font-size: 1.5rem;
  }
  .ModalRegister_loginImage__v6HFp {
    width: 30rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ModalRegister/ModalRegister.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,mCAAA;EACA,eAAA;AACJ;AAAI;EACI,mBAAA;AAER;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI;IACI,mBAAA;IACA,SAAA;EACN;EAEE;IACI,kBAAA;EAAN;EAGE;IACI,YAAA;EADN;AACF;AAIA;EACI;IACI,UAAA;EAFN;EAKE;IACI,iBAAA;EAHN;EAME;IACI,YAAA;EAJN;AACF","sourcesContent":[".loginImage {\n    width: 15rem;\n    border-radius: 2rem;\n}\n\n.registerContainer {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    margin: 1.5rem 0;\n}\n\n.formModalContainer {\n    display: flex;\n    flex-direction: column;\n}\n\n.formStyle {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.registerButton {\n    padding: 1rem 2rem;\n    border-radius: 2rem;\n    border: none;\n    background-color: var(--main-color);\n    cursor: pointer;\n    &__bottom {\n        margin-bottom: 1rem;\n    }\n}\n\n.hLine {\n    width: 30rem;\n}\n\n@media screen and (min-width:1024px) {\n    .registerContainer {\n        flex-direction: row;\n        gap: 7rem;\n    }\n\n    .registerButton {\n        font-size: 1.25rem;\n    }\n\n    .loginImage {\n        width: 25rem;\n    }\n}\n\n@media screen and (min-width:1500px) {\n    .registerContainer {\n        gap: 12rem;\n    }\n\n    .registerButton {\n        font-size: 1.50rem;\n    }\n\n    .loginImage {\n        width: 30rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginImage": `ModalRegister_loginImage__v6HFp`,
	"registerContainer": `ModalRegister_registerContainer__8Q50d`,
	"formModalContainer": `ModalRegister_formModalContainer__eUrTD`,
	"formStyle": `ModalRegister_formStyle__f88To`,
	"registerButton": `ModalRegister_registerButton__cxZZk`,
	"registerButton__bottom": `ModalRegister_registerButton__bottom__VYVAf`,
	"hLine": `ModalRegister_hLine__1tfF8`
};
export default ___CSS_LOADER_EXPORT___;
