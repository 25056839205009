import LoginInfo from "Components/LoginInput"
import styles from "./RegisterPage.module.scss"
import classNames from "classnames";
import LoginBanner from "Components/LoginBanner";
import React, { useState } from "react";

interface Props {
    centralize:boolean;
}

export default function RegisterPage({centralize}:Props) {

    const [cpf, setCpf] = useState("");
    const [compName, setCompName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");

    const registerData = [
        {
            label: "CPF",
            state: cpf,
            stateSetter: setCpf
        },
        {
            label: "Complete name",
            state: compName,
            stateSetter: setCompName
        },
        {
            label: "Mail",
            state: mail,
            stateSetter: setMail
        },
        {
            label: "Phone",
            state: phone,
            stateSetter: setPhone
        },
        {
            label: "Country",
            state: country,
            stateSetter: setCountry
        },
        {
            label: "Password",
            state: password,
            stateSetter: setPassword
        },
        {
            label: "Confirm your password",
            state: confirm,
            stateSetter: setConfirm
        },

    ]

    const onSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        cleanForm();
    }

    function cleanForm() {
        registerData.forEach(content => {
            return content.stateSetter("");
        })
    }

    return (
        <div className={styles.cont}>
            <LoginBanner/>
            
            <div className={styles["register-container"]}>
                <form onSubmit={onSave} className={classNames({
                    [styles["register-page"]]:true,
                    [styles["register-page__centralize"]]:centralize
                })}>
                    <h2 className={styles.title}>Fill the fields</h2>
                    {registerData.map(content => {
                        return (
                            <LoginInfo 
                                onChanging={str => content.stateSetter(str)}
                                value={content.state}
                                key={content.label} 
                                field={content.label}
                            />
                        )
                    })}
                    <button className={styles["register-button"]}>
                        Register
                    </button>
                </form>
            </div>
        </div>

    )
}