import Chart from "react-google-charts"
import styles from "./DonutChart.module.scss"

export default function DonutChart() {

    const data = [
        ["Task", "Hours per Day"],
        ["Arábica", 11],
        ["Conilon", 2]
      ];
      
    const options = {
        pieHole: 0.4,
        is3D: false,
        backgroundColor:"transparent",
        pieSliceText: 'label',
        pieSliceTextStyle: {
            fontSize: 16, 
            },
        legend: {
            position: 'labeled', 
            textStyle: { fontSize: 16 }, 
        },
      };

    return (
        <div className={styles.donutContainer}>
            <h2 className={styles.donutTitle}> Sample Quantity </h2>
            <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={data}
                options={options}
            />
        </div>
    )
}