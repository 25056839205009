import { ReactNode } from "react";
import styles from "./Modal.module.scss"

export interface ModalProps {
    title: string;
    children?: ReactNode;
    open: boolean;
    onClose: () => void;
}

const Modal = ({ title, children, open, onClose }:ModalProps) => {
    
    if (!open) {
        return (
            <></>
        )
    }
    
    return (
        <>    
            <div onClick={onClose} className={styles.modalBackground}/>
            <div className={styles.modalMainWindow}>
                <div className={styles.modalTitleWrapper}>
                    <h2 className={styles.modalTitle}>
                        {title}
                    </h2>
                    <button
                        onClick={onClose}
                        className={styles.modalButton}
                    >
                        X
                    </button>
                </div>
                {children}
            </div>
        </>
    )
}

export default Modal;