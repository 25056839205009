// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Report_favcon__-8-mQ {
  width: 5rem;
  height: 5rem;
}

.Report_downloadContainer__A5udG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Report_downloadIcons__OhaLd {
  display: flex;
  align-items: center;
}

.Report_pdfViewer__8Kw0n {
  width: 60%;
  height: 70%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Report/Report.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;AACJ","sourcesContent":[".favcon {\n    width: 5rem;\n    height: 5rem;\n}\n\n.downloadContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.downloadIcons {\n    display: flex;\n    align-items: center;\n}\n\n.pdfViewer {\n    width: 60%;\n    height: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"favcon": `Report_favcon__-8-mQ`,
	"downloadContainer": `Report_downloadContainer__A5udG`,
	"downloadIcons": `Report_downloadIcons__OhaLd`,
	"pdfViewer": `Report_pdfViewer__8Kw0n`
};
export default ___CSS_LOADER_EXPORT___;
